const EVENTS_REQUEST = 'EVENTS_REQUEST';
const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
const EVENTS_FAILURE = 'EVENTS_FAILURE';
const MORE_EVENTS_SUCCESS = 'MORE_EVENTS_SUCCESS';
const SINGLE_EVENT_REQUEST = 'SINGLE_EVENT_REQUEST';
const SINGLE_EVENT_SUCCESS = 'SINGLE_EVENT_SUCCESS';
const SINGLE_EVENT_FAILURE = 'SINGLE_EVENT_FAILURE';

export {
  EVENTS_REQUEST,
  EVENTS_SUCCESS,
  EVENTS_FAILURE,
  MORE_EVENTS_SUCCESS,
  SINGLE_EVENT_FAILURE,
  SINGLE_EVENT_REQUEST,
  SINGLE_EVENT_SUCCESS,
};
