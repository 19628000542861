const EVENTS = [
  {
    id: 26,
    title_es: 'EthereumBA: Ethereum en 2021 + De Buenos Aires a DeFI,  una charla con Will Price y Brendan Forster',
    title_en: 'EthereumBA: Ethereum en 2021 + De Buenos Aires a DeFI,  una charla con Will Price y Brendan Forster',
    title_pt: 'EthereumBA: Ethereum en 2021 + De Buenos Aires a DeFI,  una charla con Will Price y Brendan Forster',
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 17 de diciembre.\r\n\r\n--------\r\n\r\nEthereum en 2021\r\n\r\nVitalik Buterin \r\n@VitalikButerin\r\n\r\n--------\r\n\r\nDe Buenos Aires a DeFI, una charla con Will Price y Brendan Forster\r\n\r\nWill Price \r\n@will__price\r\n\r\nBrendan Forster | Dharma\r\n@brendan_dharma\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_en: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 17 de diciembre.\r\n\r\n--------\r\n\r\nEthereum en 2021\r\n\r\nVitalik Buterin \r\n@VitalikButerin\r\n\r\n--------\r\n\r\nDe Buenos Aires a DeFI, una charla con Will Price y Brendan Forster\r\n\r\nWill Price \r\n@will__price\r\n\r\nBrendan Forster | Dharma\r\n@brendan_dharma\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_pt: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 17 de diciembre.\r\n\r\n--------\r\n\r\nEthereum en 2021\r\n\r\nVitalik Buterin \r\n@VitalikButerin\r\n\r\n--------\r\n\r\nDe Buenos Aires a DeFI, una charla con Will Price y Brendan Forster\r\n\r\nWill Price \r\n@will__price\r\n\r\nBrendan Forster | Dharma\r\n@brendan_dharma\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/274747390/?isFirstPublish=true',
    youtube_url: 'https://www.youtube.com/channel/UCxjpRkAqct3TJX1vAONgX2w',
    date: '2020-12-17T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: null,
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 25,
    title_es: 'EthereumBA: Democracia en la era de la información + Status + El futuro de xDai',
    title_en: 'EthereumBA: Democracia en la era de la información + Status + El futuro de xDai',
    title_pt: 'EthereumBA: Democracia en la era de la información + Status + El futuro de xDai',
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 26 de noviembre.\r\n\r\n--------\r\nDemocracia en la era de la información\r\n\r\nSanti Siri | Democracy.Earth\r\n@santisiri\r\n\r\n--------\r\nCómo Status usa Ethereum para tener mensajería privada y resistente a la censura\r\n\r\nJuan David Reyes | Status\r\n@jdreyespaez\r\n\r\n--------\r\nEl futuro de xDai\r\n\r\nManuel Garcia | Protofire\r\n@rmgarciap\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_en: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 26 de noviembre.\r\n\r\n--------\r\nDemocracia en la era de la información\r\n\r\nSanti Siri | Democracy.Earth\r\n@santisiri\r\n\r\n--------\r\nCómo Status usa Ethereum para tener mensajería privada y resistente a la censura\r\n\r\nJuan David Reyes | Status\r\n@jdreyespaez\r\n\r\n--------\r\nEl futuro de xDai\r\n\r\nManuel Garcia | Protofire\r\n@rmgarciap\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_pt: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo jueves 26 de noviembre.\r\n\r\n--------\r\nDemocracia en la era de la información\r\n\r\nSanti Siri | Democracy.Earth\r\n@santisiri\r\n\r\n--------\r\nCómo Status usa Ethereum para tener mensajería privada y resistente a la censura\r\n\r\nJuan David Reyes | Status\r\n@jdreyespaez\r\n\r\n--------\r\nEl futuro de xDai\r\n\r\nManuel Garcia | Protofire\r\n@rmgarciap\r\n\r\n--------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/274747390/?isFirstPublish=true',
    youtube_url: 'https://www.youtube.com/channel/UCxjpRkAqct3TJX1vAONgX2w',
    date: '2020-11-26T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/87301356288?pwd=dEh0cjJUMUxzaGI5WG9SOUxnaDJpdz09',
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 22,
    title_es: 'Ethereum BA:  Synthetix 101 + Synthetix Optimism',
    title_en: 'Ethereum BA:  Synthetix 101 + Synthetix Optimism',
    title_pt: 'Ethereum BA:  Synthetix 101 + Synthetix Optimism',
    description_es: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 24 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo miércoles 28 de octubre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nSynthetix 101\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n----\r\n\r\nSynthetix Optimism\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el miércoles!',
    description_en: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 24 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo miércoles 28 de octubre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nSynthetix 101\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n----\r\n\r\nSynthetix Optimism\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el miércoles!',
    description_pt: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 24 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo miércoles 28 de octubre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nSynthetix 101\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n----\r\n\r\nSynthetix Optimism\r\nAlejandro Santander @ Synthetix\r\n@the_ethernaut\u2029\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el miércoles!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/274169914/',
    youtube_url: 'https://www.youtube.com/channel/UCxjpRkAqct3TJX1vAONgX2w',
    date: '2020-10-28T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/89737952406?pwd=TWJZaXl1K2daVktRWHVNSmlZZEczQT09',
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 21,
    title_es: 'Ethereum BA:  La comunidad de yEarn +  La Triforce de YFI, ETH y MKR',
    title_en: 'Ethereum BA:  La comunidad de yEarn +  La Triforce de YFI, ETH y MKR',
    title_pt: 'Ethereum BA:  La comunidad de yEarn +  La Triforce de YFI, ETH y MKR',
    description_es: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 23 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo jueves 24 de septiembre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nLa comunidad de yEarn\r\nFacundo Ameal | yEarn\r\n@fameal\r\n----\r\nLa Triforce de YFI, ETH y MKR\r\nMariano Conti | Gran tipo\r\n@nanexcool\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el jueves!',
    description_en: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 23 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo jueves 24 de septiembre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nLa comunidad de yEarn\r\nFacundo Ameal | yEarn\r\n@fameal\r\n----\r\nLa Triforce de YFI, ETH y MKR\r\nMariano Conti | Gran tipo\r\n@nanexcool\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el jueves!',
    description_pt: '¡Invitamos a toda la comunidad Ethereum & Blockchain al meetup número 23 de EthereumBA.\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo jueves 24 de septiembre, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\nLa comunidad de yEarn\r\nFacundo Ameal | yEarn\r\n@fameal\r\n----\r\nLa Triforce de YFI, ETH y MKR\r\nMariano Conti | Gran tipo\r\n@nanexcool\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el jueves!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/270786782/',
    youtube_url: 'https://www.youtube.com/watch?v=n3cmUCNFYpA&feature=youtu.be',
    date: '2020-09-24T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/88535928861',
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 24,
    title_es: 'Ethereum BA: Entendiendo Ethereum 2.0: Charla + Fireside Chat',
    title_en: 'Ethereum BA: Entendiendo Ethereum 2.0: Charla + Fireside Chat',
    title_pt: 'Ethereum BA: Entendiendo Ethereum 2.0: Charla + Fireside Chat',
    description_es: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 27 de agosto.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y las charlas serán en español.\r\n\r\n------\r\n\r\nEntendiendo Ethereum 2.0\r\n\r\nCharla\r\n\r\nJustin Drake - Ethereum Foundation\r\n\r\nFireside Chat\r\n\r\nEvan Van Ness - Week in Ethereum\r\nJustin Drake - Ethereum Foundation\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_en: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 27 de agosto.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y las charlas serán en español.\r\n\r\n------\r\n\r\nEntendiendo Ethereum 2.0\r\n\r\nCharla\r\n\r\nJustin Drake - Ethereum Foundation\r\n\r\nFireside Chat\r\n\r\nEvan Van Ness - Week in Ethereum\r\nJustin Drake - Ethereum Foundation\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_pt: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 27 de agosto.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y las charlas serán en español.\r\n\r\n------\r\n\r\nEntendiendo Ethereum 2.0\r\n\r\nCharla\r\n\r\nJustin Drake - Ethereum Foundation\r\n\r\nFireside Chat\r\n\r\nEvan Van Ness - Week in Ethereum\r\nJustin Drake - Ethereum Foundation\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/272767472/',
    youtube_url: 'https://www.youtube.com/channel/UCxjpRkAqct3TJX1vAONgX2w',
    date: '2020-08-27T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/83125305421',
    talks: [],
    photos: [],
    is_active: true,
  },
  {
    id: 23,
    title_es: 'Ethereum BA: Charla Proof of Liquidity + Panel: Incentivos de liquidez en DeFi',
    title_en: 'Ethereum BA: Charla Proof of Liquidity + Panel: Incentivos de liquidez en DeFi',
    title_pt: 'Ethereum BA: Charla Proof of Liquidity + Panel: Incentivos de liquidez en DeFi',
    description_es: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 30 de julio.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y en español.\r\n\r\n------\r\n\r\nCharla\r\n\r\nProof of liquidity\r\nJoel Monegro - Placeholder\r\n\r\nPanel: Incentivos de liquidez en DeFi\r\nModeradora Camila Russo - The Defiant\r\n\r\nPanelistas:\r\nJoel Monegro - Placeholder\r\nSantiago Roel Santos - ParaFi Capital\r\nPablo Candela - Aave\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_en: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 30 de julio.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y en español.\r\n\r\n------\r\n\r\nCharla\r\n\r\nProof of liquidity\r\nJoel Monegro - Placeholder\r\n\r\nPanel: Incentivos de liquidez en DeFi\r\nModeradora Camila Russo - The Defiant\r\n\r\nPanelistas:\r\nJoel Monegro - Placeholder\r\nSantiago Roel Santos - ParaFi Capital\r\nPablo Candela - Aave\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    description_pt: 'Detalles\r\nInvitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 30 de julio.\r\n\r\nNuevamente, el meetup será realizado de forma 100% online y en español.\r\n\r\n------\r\n\r\nCharla\r\n\r\nProof of liquidity\r\nJoel Monegro - Placeholder\r\n\r\nPanel: Incentivos de liquidez en DeFi\r\nModeradora Camila Russo - The Defiant\r\n\r\nPanelistas:\r\nJoel Monegro - Placeholder\r\nSantiago Roel Santos - ParaFi Capital\r\nPablo Candela - Aave\r\n\r\n------\r\n\r\nAfter\r\n\r\nEste jueves no será la excepción y cerraremos con un espacio de networking. Pero, dado que el meetup será realizado 100% online, te recomendamos no olvidar traer tu propia cerveza o bebida preferida :)\r\n\r\n¡Los esperamos!',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/272171514/',
    youtube_url: 'https://www.youtube.com/channel/UCxjpRkAqct3TJX1vAONgX2w',
    date: '2020-07-30T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/86057250547',
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 20,
    title_es: 'Ethereum Chicago & BA: Uniswap & Balancer',
    title_en: 'Ethereum Chicago & BA: Uniswap & Balancer',
    title_pt: 'Ethereum Chicago & BA: Uniswap & Balancer',
    description_es: '¡El próximo meetup es muy especial ya que lo vamos a hacer en conjunto con nuestros amigos de Ethereum Chicago!\r\n\r\nLa idea nació en una charla y escaló rápidamente a un grupo de Telegram donde terminamos de cocinar los detalles.\r\n\r\nHasta donde sabemos, este va a ser el primer meetup hecho en conjunto por dos comunidades de Ethereum.\r\n\r\nY para una ocasión tan especial ¡teníamos que armar un line-up espectacular!\r\n\r\n------\r\n\r\nCharlas (20 min. + 5 min Q&A para cada una)\r\n\r\nConstruyendo sobre Balancer\r\nFernando Martinelli - Balancer\r\n\r\nUniswap V2 y el futuro de los AMMs\r\nNoah Zinsmeister - Uniswap\r\n\r\n------\r\n\r\nPanel (20-30 min)\r\n\r\nFernando + Noah responderán preguntas de los MCs del meetup y de los miembros de la comunidad.\r\n\r\n------\r\n\r\nLinks de los oradores\r\nhttps://twitter.com/fcmartinelli\r\nhttps://twitter.com/NoahZinsmeister\r\n\r\nLinks de los proyectos\r\nhttps://twitter.com/BalancerLabs\r\nhttps://twitter.com/UniswapProtocol',
    description_en: 'This upcoming meetup is very special since we\'re getting together with our friends from Ethereum Chicago!\r\n\r\nThe idea started with an initial conversation that quickly escalated into a Telegram group, where we figured out all the details.\r\n\r\nAs far as we know, this will be the first joint meetup to be put on by two different Ethereum communities.\r\n\r\nAnd for such an occasion, we knew we needed a great line-up!\r\n\r\n----\r\n\r\nTalks (20 min. + 5 min of Q&A for each)\r\n\r\nBuilding on top of Balancer\r\nFernando Martinelli - Balancer\r\n\r\nUniswap V2 and the future of AMMs\r\nNoah Zinsmeister - Uniswap\r\n\r\n+\r\n\r\nPanel (20-30 min)\r\n\r\nFernando + Noah will answer questions from the meetup MCs and the community members.\r\n\r\n----\r\n\r\nSpeaker links:\r\nhttps://twitter.com/fcmartinelli\r\nhttps://twitter.com/NoahZinsmeister\r\n\r\nProject links:\r\nhttps://twitter.com/BalancerLabs\r\nhttps://twitter.com/UniswapProtocol',
    description_pt: '',
    meetup_url: 'https://www.meetup.com/EthereumBA/events/271219315/',
    youtube_url: null,
    date: '2020-06-25T20:00:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: null,
    talks: [],
    photos: [],
    is_active: true,
  }, {
    id: 19,
    title_es: 'Ethereum BA: Privacidad con Wibson + Wrapped Tokens + Pagos DeFi con StablePay',
    title_en: null,
    title_pt: null,
    description_es: '¡Invitamos a toda la comunidad Ethereum & Blockchain al 3er meetup online de Ethereum Buenos Aires de 2020!\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo jueves 28 de mayo, a las 7:30 PM (GMT-3).\r\n\r\n\r\n------\r\n\r\nCharlas\r\n\r\nWibson: por qué la privacidad importa y cómo controlar tus datos.\r\nRodrigo Irarrázaval - Wibson.\r\n\r\nWrapped Tokens: lo bueno y lo malo.\r\nLuciano Orlando - DeFi Latam.\r\n\r\nStablePay: plataforma DeFi de pagos.\r\nDouglas Molina & Guillermo Salazar - StablePay.\r\n\r\n------\r\n\r\nAfter\r\n\r\nAl igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!\r\n\r\n------\r\n\r\nNos vemos el jueves!',
    description_en: '',
    description_pt: '',
    meetup_url: 'https://www.meetup.com/es-ES/EthereumBA/events/270786782/',
    youtube_url: 'https://www.youtube.com/watch?v=n3cmUCNFYpA&feature=youtu.be',
    date: '2020-05-28T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us02web.zoom.us/j/83679596341',
    talks: [{
      id: 57,
      name_es: 'After',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Al igual que la última vez, luego de disfrutar de las charlas compartiremos un espacio virtual de networking. ¡No te olvides de traer tu bebida o snack favorito!',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 56,
      name_es: 'StablePay: plataforma DeFi de pagos',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:30:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{
        id: 15,
        name: 'Presentation',
        file: '/media/StablePay__plataforma_DeFi_de_pagos_-_Douglas_Molina__Guillermo_Salazar_StablePay.pdf',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Douglas Molina & Guillermo Salazar - StablePay',
      description_en: '',
      description_pt: '',
      podcast: 'https://open.spotify.com/embed-podcast/episode/7h2juBWwqpn8lxwWNTYjTp',
    }, {
      id: 54,
      name_es: 'Wibson: por qué la privacidad importa y cómo controlar tus datos',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:30:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{
        id: 13,
        name: 'Presentation',
        file: '/media/Wibson_por_que_la_privacidad_importa_y_como_controlar_tus_datos-Rodrigo_Irarrazaval_Wibson.pdf',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Rodrigo Irarrázaval - Wibson',
      description_en: '',
      description_pt: '',
      podcast: 'https://open.spotify.com/embed-podcast/episode/4Ae4QMURjYPOr5tCJvNp5K',
    }, {
      id: 55,
      name_es: 'Wrapped Tokens: lo bueno y lo malo',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{
        id: 14,
        name: 'Presentation',
        file: '/media/Wrapped_Tokens__lo_bueno_y_lo_malo_-_Luciano_Orlando_DeFi_Latam.pdf',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Luciano Orlando - DeFi Latam',
      description_en: '',
      description_pt: '',
      podcast: 'https://open.spotify.com/embed-podcast/episode/7CQPZ2fQ5opPaWZLyDRWxx',
    }],
    photos: [],
    is_active: true,
  }, {
    id: 18,
    title_es: 'Ethereum BA: MakerDAO\'s Black Thursday Survival + Decentraland\'s Metaverse Release',
    title_en: null,
    title_pt: null,
    description_es: '¡Invitamos a toda la comunidad Ethereum & Blockchain al 2do meetup online de Ethereum Buenos Aires de 2020!\r\n\r\nEn esta oportunidad, el evento será llevado a cabo via Zoom el próximo jueves 30 de abril, a las 19:30 PM GMT-3.\r\n\r\nZoom: https://us04web.zoom.us/j/994444810\r\n\r\n------\r\n\r\nEthereum Buenos Aires Recap\r\n\r\n¡Este abril Ethereum Buenos Aires cumple 18 meses! ¡Descubrí más sobre sus orígenes, actividad durante 2019, campaña para #DevconBA2020 y planes futuros en nuestro increíble Recap!\r\n\r\nPodés leerlo en https://ethereumba.substack.com/p/ethereum-ba-recap-2018-2020\r\n\r\n------\r\n\r\nNos vemos el jueves!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/es/EthereumBA/events/270289895/',
    youtube_url: 'https://www.youtube.com/watch?v=87s6nuI_QRk',
    date: '2020-04-30T19:30:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us04web.zoom.us/j/994444810',
    talks: [{
      id: 51,
      name_es: 'After',
      name_en: null,
      name_pt: null,
      speaker: '--',
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Just like the last time, after enjoying the talks we\'ll share a virtual networking space. Make sure to bring your favorite drink or snack!',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 49,
      name_es: 'MakerDAO: Surviving crypto\'s biggest market crash',
      name_en: null,
      name_pt: null,
      speaker: 'Mariano Conti',
      time: '19:30:00',
      language: 'ENGLISH',
      language_display: 'english',
      level: null,
      level_display: null,
      material: [{
        id: 1,
        name: 'Presentation',
        file: '/media/MakerDAO__Surviving_cryptos_biggest_market_crash.pdf',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Mariano Conti - MakerDAO',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 50,
      name_es: 'Decentraland: A metaverse powered by smart contracts and community consensus',
      name_en: null,
      name_pt: null,
      speaker: 'Esteban Ordano',
      time: '20:15:00',
      language: 'ENGLISH',
      language_display: 'english',
      level: null,
      level_display: null,
      material: [{
        id: 2,
        name: 'Presentation',
        file: '/media/Decentraland_A_metaverse_powered_by_smart_contracts_and_community_consensus.pdf',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Esteban Ordano - Decentraland',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 17,
    title_es: 'Ethereum BA: Black Thursday + MakerDAO Backstop Syndicate',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 26 de marzo.\r\n\r\nEn esta oportunidad, el meetup será realizado de forma 100% online.\r\n\r\nPara participar del meetup, por favor accede a través del siguiente link de Zoom => https://us04web.zoom.us/j/994444810\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/269591010/',
    youtube_url: 'https://www.youtube.com/watch?v=vaJetEDnXs8',
    date: '2020-03-26T19:00:00',
    place_name: null,
    place_street: 'Online',
    place_number: 'conference',
    place_city: 'Zoom',
    place_map_url: 'https://us04web.zoom.us/j/994444810',
    talks: [{
      id: 46,
      name_es: 'Black Thursday',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'El impacto de los acontecimientos del 12 de marzo en el ecosistema Ethereum y DeFi\r\nPablo Candela Andrade @Aave',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 47,
      name_es: 'MakerDAO Backstop Syndicate',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Un esfuerzo espontáneo de la comunidad Ethereum para asegurar una subasta exitosa de MKR\r\nPatricio Worthalter @ POAP',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 48,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  },
  {
    id: 16,
    title_es: 'Ethereum BA: DeFi + Loans',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 27/02 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/268936409/',
    youtube_url: 'https://www.youtube.com/watch?v=Jl9F6GMDv34',
    date: '2020-02-27T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 43,
      name_es: 'Triple Digits APR: how RCN brings Latin American Fiat Loans to DeFi',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Manuel Gvirtz @RCN',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 44,
      name_es: 'Flash Loans: how DeFi Composability Turns Features into Exploits',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Alejo Salles @OpenZeppelin y Sebastián Aldasoro',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 45,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 15,
    title_es: 'Ethereum BA: console.log en Solidity + Contratos no tan inmutables',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 16/01 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/267779588/',
    youtube_url: 'https://www.youtube.com/watch?v=1c1MrA4pKJg',
    date: '2020-01-16T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 40,
      name_es: 'Implementando console.log en Solidity',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Patricio Palladino, CTO @NomicLabs',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 41,
      name_es: 'Contratos no tan inmutables',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Nicolas Venturo, Software Engineer @OpenZeppelin',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 42,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 14,
    title_es: 'Ethereum BA: Ultimo del año!! + Multi Collateral DAI y LocalCryptos',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 19/12 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/267160924/',
    youtube_url: 'https://www.youtube.com/watch?v=lgAnpy6xw_Y',
    date: '2019-12-19T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 37,
      name_es: 'Un mercado P2P sin custodia',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 7, name: 'Slides', file: '/media/LocalCryptos.pdf', is_active: true }],
      is_active: true,
      description_es: 'Santiago Sánchez y Aníbal Salazar @LocalCryptos',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 38,
      name_es: 'Much Cooler Dai - (MCD)',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 8, name: 'Slides', file: '/media/MCD_-_Ethereum_Buenos_Aires.pdf', is_active: true }],
      is_active: true,
      description_es: 'Mariano Conti @ MakerDAO',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 39,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 13,
    title_es: 'Ethereum BA: Aragon',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Lunes 2/12 a las 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/266607004/',
    youtube_url: 'https://www.youtube.com/watch?v=KbJDqqLQF_Y',
    date: '2019-12-02T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 34,
      name_es: 'Como usar DAOs para organizarse sin el gobierno',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 11, name: 'Slides', file: '/media/Aragon_Buenos_Aires_meetup.pdf', is_active: true }],
      is_active: true,
      description_es: 'Luis Cuende - Executive Director @Aragon Association\r\n\r\nLuis nos contará cómo puedes fácilmente crear una organización global en Internet en solo unos minutos para organizarte con amigos, compañeros de trabajo o gente en Internet.',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 35,
      name_es: 'Aragon Chain – a proof of stake blockchain para la comunidad de Aragon',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 10, name: 'Slides', file: '/media/Aragon_Chain.pdf', is_active: true }],
      is_active: true,
      description_es: 'Jorge Izquierdo - CEO @Aragon One',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 36,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 58,
      name_es: 'Aragon Court',
      name_en: null,
      name_pt: null,
      speaker: 'Facundo Spagnuolo',
      time: '01:01:17',
      language: 'SPANISH',
      language_display: 'spanish',
      level: 'MEDIUM',
      level_display: 'medium',
      material: [{ id: 12, name: 'Slides', file: '/media/Aragon_Court_1.pdf', is_active: true }],
      is_active: true,
      description_es: '',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 12,
    title_es: 'Ethereum BA: Diaspore + Wibson reward marketplace',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 24/10 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/265766794/',
    youtube_url: 'https://www.youtube.com/watch?v=ZpouMfuQF7c',
    date: '2019-10-24T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 31,
      name_es: 'Diaspore: an Open Finance Protocol to bridge DeFi and LatAm Debt Markets',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Agustin Aguilar - CTO @ RCN',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 32,
      name_es: 'Wibson Reward Marketplace',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{
        id: 9,
        name: 'SLIDES',
        file: '/media/Reward_Marketplace_presentation_t2p05z4.pptx',
        is_active: true,
      }, { id: 6, name: 'Slides', file: '/media/Reward_Marketplace_presentation.pptx', is_active: true }],
      is_active: true,
      description_es: 'Rodrigo Irarrazaval - Head of Business Dev. @ Wibson',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 33,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  },
  {
    id: 11,
    title_es: 'Ethereum BA: DeFi (Ch. 3) + Intro a POAP',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 26/09 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/265021488/',
    youtube_url: 'https://www.youtube.com/watch?v=KxMiU9h0fNY',
    date: '2019-09-26T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 28,
      name_es: 'Como ganar interés con aplicaciones DeFi (sin moverte de tu casa)',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 5, name: 'Slides', file: '/media/CTF-ETHBA26.pdf', is_active: true }],
      is_active: true,
      description_es: 'Lucas Palomeque y Brian Prilick @ CTF',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 29,
      name_es: 'Estamos en Harvard...',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'La experiencia del equipo POAP en ETHBoston\r\nPatricio Worthalter y Bruno Balzani @ POAP - Ramiro Gonzalez @ Xivis',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 30,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 10,
    title_es: 'Ethereum BA: xDAI + Experimental',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain a participar de un nuevo encuentro el próximo Jueves 29/08 19:00 hs en AreaTres (El Salvador 5218), Buenos Aires.\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/264159878/',
    youtube_url: 'https://www.youtube.com/watch?v=-AdQe3vPMZU',
    date: '2019-08-29T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 25,
      name_es: 'From the stable coin DAI to xDAI, a stable cost Chain',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: '¿Pensabas que una USD-Stable Blockchain era imposible, no? Bueno, no lo es.\r\n\r\nManuel Garcia, CTO de Protofire, nos cuenta todo sobre cómo agregar liquidez al ecosistema de aplicaciones de Finanzas Descentralizadas.',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 26,
      name_es: 'Como reventar Loom Network dos veces en una semana',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: '¿Te acordás cuando CryptoKitties frenaba las transacciones en Ethereum?\r\n\r\nLuciano Bertenasco CTO de Experimental y Alejo Amiras, Developer en Experimental, nos cuentan cómo hicieron lo mismo con Loom.',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 27,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 9,
    title_es: 'Ethereum BA: Escalando blockchain + intro a Celo',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 25/07 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/263358508/',
    youtube_url: 'https://www.youtube.com/watch?v=F2z9fI61pTU',
    date: '2019-07-25T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 22,
      name_es: 'Intro a Celo',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Mariano Cortesi @Celo',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 23,
      name_es: 'Escalar Blockchain sin morir en el intento',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Nicolás Ayala y Nicolás @ Wibson',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 24,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 8,
    title_es: 'Ethereum BA: Lightning + DeFi',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 27/06 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/262519418/',
    youtube_url: 'https://www.youtube.com/watch?v=um_ve6KKStM',
    date: '2019-06-27T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 19,
      name_es: 'Lightning Network for Ethereans',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Federico Bond - Lightning Network Engineer @ Muun',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 20,
      name_es: 'DeFi, Chapter 2',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Mariano Di Pietrantonio - Community Lead @ Maker DAO',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 21,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 7,
    title_es: 'Ethereum BA: Escalando un mercado basado en blockchain',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 30/05 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/261813721/',
    youtube_url: 'https://www.youtube.com/watch?v=EQ75oekSlO8',
    date: '2019-05-30T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 16,
      name_es: 'Consideraciones para escalar un mercado basado en Blockchain',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Agustín Modugno y Nicolás Ayala, Devs @ Wibson',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 17,
      name_es: 'RSK + RIF: infraestructura para tus contratos inteligentes sobre Bitcoin',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Sebastián Perez, Senior Dev @ RSK',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 18,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  },
  {
    id: 6,
    title_es: 'Ethereum BA: Smart Contracts Upgrades & DeFi',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 02/05 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/261018853/',
    youtube_url: 'https://www.youtube.com/watch?v=zbkDQfAHLC4',
    date: '2019-05-02T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 14,
      name_es: 'Finanzas Descentralizadas - DeFi',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{ id: 4, name: 'Slides', file: '/media/DEFI.pdf', is_active: true }],
      is_active: true,
      description_es: 'Mariano Di Pietrantonio, Community Lead @ MakerDAO',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 13,
      name_es: 'Upgrades in an immutable world: an introduction to ZeppelinOS',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [{
        id: 3,
        name: 'Slides',
        file: '/media/Upgrades_in_an_immutable_world__An_introduction_to_ZeppelinOS_-_Juan_Bautista_Carpanelli_2.pptx',
        is_active: true,
      }],
      is_active: true,
      description_es: 'Jota Carpanelli, Core Dev @ Zeppelin',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 15,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 5,
    title_es: 'Ethereum BA: RadicalxChange + 1 a 1: How Dai is pegged to USD',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Viernes 29/03 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/259993070/',
    youtube_url: 'https://www.youtube.com/watch?v=rFfccL5gw_U',
    date: '2019-03-28T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 11,
      name_es: 'Radical x Change',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'ENGLISH',
      language_display: 'english',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'E. Glen Weyl, Chair @ Radical x Change',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 10,
      name_es: 'Horizontal Scaling on Ethereum',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Manuel Garcia @Protofire',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 12,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 4,
    title_es: 'Ethereum BA: Buidler + Marmo SDK + Zerion',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo encuentro el próximo Jueves 28/02 19:00 hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/259266153/',
    youtube_url: 'https://www.youtube.com/watch?v=iiw1vRJ19Jg',
    date: '2019-02-28T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 6,
      name_es: 'Zerion: Unifying Decentralized Finance',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'ENGLISH',
      language_display: 'english',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Evgeny Yurtaev CEO @ Zerion',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 7,
      name_es: 'Marmo SDK: introducción a las meta-transacciones en Ethereum',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Agustín Aguilar CTO @RCN',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 8,
      name_es: 'Buidler: Mejorando las dev tools P',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Patricio Palladino Co-founder & CTO @ Nomic Labs',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 9,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 3,
    title_es: 'Ethereum BA: Seguridad en Smart Contracts',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo meetup el próximo Jueves 31/01 19:00hs en AreaTres (El Salvador 5218).\r\n\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/258416780/',
    youtube_url: 'https://www.youtube.com/watch?v=jytqcO3WlYc',
    date: '2019-01-31T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 4,
      name_es: 'Seguridad en Smart Contracts',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Martin Abbatemarco @Zeppelin',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 5,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  }, {
    id: 2,
    title_es: 'Ethereum BA: Smart Contracts for Beginners + Introducing the Ethereum BA Community',
    title_en: null,
    title_pt: null,
    description_es: 'Invitamos a toda la comunidad Ethereum & Blockchain related devs en Buenos Aires a participar de un nuevo meetup el próximo Jueves 13/12 18:30hs en AreaTres (El Salvador 5218)\r\nLos esperamos!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/257043930/',
    youtube_url: 'https://www.youtube.com/watch?v=_OsnHWowJPg',
    date: '2018-12-13T19:00:00',
    place_name: 'Area Tres El Salvador',
    place_street: 'El Salvador',
    place_number: '5520',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/place/El+Salvador+5220,+Buenos+Aires/@-34.5859841,-58.432206,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb58edc41d1d7:0x5643469d4c8882dd!8m2!3d-34.5859841!4d-58.432206',
    talks: [{
      id: 1,
      name_es: 'Ethereum 101: smart contracts for beginner',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '19:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Agustín Aguilar CTO @RCN',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 2,
      name_es: 'Introducing ETH BA community',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '20:00:00',
      language: 'SPANISH',
      language_display: 'spanish',
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Participantes Zeppelin, Xivis, MakerDAO, Decentraland, RCN',
      description_en: null,
      description_pt: null,
      podcast: null,
    }, {
      id: 3,
      name_es: 'Networking + beers',
      name_en: null,
      name_pt: null,
      speaker: null,
      time: '21:00:00',
      language: null,
      language_display: null,
      level: null,
      level_display: null,
      material: [],
      is_active: true,
      description_es: 'Como siempre, cerramos con un espacio de Networking potenciado por pizzas + beers',
      description_en: null,
      description_pt: null,
      podcast: null,
    }],
    photos: [],
    is_active: true,
  },
  {
    id: 1,
    title_es: 'Ethereum BA: Meet & Greet',
    title_en: null,
    title_pt: null,
    description_es: '¡Ya es hora de juntarnos y conocernos!\r\n\r\nInvitamos a toda la comunidad Crypto Dev en Buenos Aires a reunirse en un meet-and-greet con cerveza y comida para picar el día 22 de noviembre a las 18:30 hs en Fly Taco Bar (República Dominicana 3382, Palermo).\r\n\r\nEn las próximas reuniones estaremos hosteando charlas y workshops técnicos con referentes de la comunidad para que nos cuenten sus experiencias en el espacio.\r\n\r\nWelcome to web 3.0!',
    description_en: null,
    description_pt: null,
    meetup_url: 'https://www.meetup.com/EthereumBA/events/256380125/',
    youtube_url: null,
    date: '2018-11-22T18:00:00',
    place_name: 'Fly Taco',
    place_street: 'Republica Dominicana',
    place_number: '3382',
    place_city: 'CABA',
    place_map_url: 'https://www.google.com/maps/search/?api=1&query=-34.590830%2C-58.413840',
    talks: [],
    photos: [],
    is_active: true,
  },
]

export default EVENTS

