import UpcomingEventCardBackground1 from '../assets/events/upcoming-card-background1.png'
import UpcomingEventCardBackground2 from '../assets/events/upcoming-card-background2.png'
import UpcomingEventCardBackground3 from '../assets/events/upcoming-card-background3.png'
import UpcomingEventCardBackground4 from '../assets/events/upcoming-card-background4.png'
import UpcomingEventCardBackground5 from '../assets/events/upcoming-card-background5.png'

const upcomingEventCardBackgrounds = [
  UpcomingEventCardBackground1,
  UpcomingEventCardBackground2,
  UpcomingEventCardBackground3,
  UpcomingEventCardBackground4,
  UpcomingEventCardBackground5,
]

export default upcomingEventCardBackgrounds
